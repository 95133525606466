<template>
  <div class="aboutcontainer">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">

      <Title></Title>

      <div class="banner">
        <h4 style="font-size: 46px;font-weight: 600;padding-bottom:40px">关于我们</h4>
        <button @click="$router.push('/freeTrial')">联系我们</button>
      </div>

      <div class="gsIntradition">
        <h4 class="descH4">公司简介</h4>
        <span class="line"></span>
        <div class="intraditionContent">
          <div class="intraLeft" @click="play = true">
            <img src="@/assets/about/playMask.png" alt="" class="palyFengm" style="">
            <img src="@/assets/about/play.png" alt="" class="intraPlayBtn" >
          </div>
          <div class="intraRight">
            <div>
              &emsp;&emsp;北京易普行科技有限公司，成立于2010年，是国内专注于为高校提供人力资源管理与服务数字化解决方案的软件企业。
            </div>
            <div>
              &emsp;&emsp;立足高校人力资源业务的本质和数字化转型的趋势，公司秉持专业精神，将高校人力资源最佳业务实践融入到平台原型，建设了涵盖招聘与人才引进、基础人事、师资培养、全面薪酬、岗位评聘、考核测评、组干与党务、统战管理、离退休服务的高校人力资源管理与服务平台，为高校用户提供高度匹配的人力资源数字化解决方案。目前，国内高校用户已经超过400家，备受客户信赖，已成为高校人力资源管理信息化服务提供商的典范。
            </div>
          </div>
        </div>

        <div class="qiywh">
        <!-- <img class="qiywhImg" src="@/assets/about/qiwenhuaBg.png" alt=""> -->
          <div class="qiywhCon">
            <div class="qiywhItem">
              <img class="qiywhItemImg" src="@/assets/about/yuanj.png" alt="">
              <span class="qiywhItemTitlt">愿景</span>
              <span class="qiywhItemText">
                高校人力资源数字化领航者
              </span>
            </div>
            <div class="qiywhItem">
              <img class="qiywhItemImg" src="@/assets/about/shiming.png" alt="">
              <span class="qiywhItemTitlt">使命</span>
              <span class="qiywhItemText" >
                我们坚信人力资源管理在高校变革与发展过程中的专业价值，易普行的使命，就是让这种价值得到最大程度的发挥和体现
              </span>
            </div>
            <div class="qiywhItem">
              <img class="qiywhItemImg" src="@/assets/about/jiazhig.png" alt="">
              <span class="qiywhItemTitlt">价值观</span>
              <span class="qiywhItemText">
                <div>专业主义、团队精神、用户立场</div>
                <div>自我驱动、学习精进、可以信赖</div>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="processContent">
        <h4 class="descH4">发展历程</h4>
        <span class="line"></span>
        <span style="font-size: 24px;color:#222;padding:20px 0 60px">易普行始终聚焦于高校人力资源信息化/数字化建设</span>
        <div class="processStep">
          <img src="@/assets/about/diedai.png" alt="" style="width:1200px">
          <div :class="{processStepItem:true,processStepActive:stepAcive === index}" v-for="(item,index) in stepList" :key="index" :style="{left:item.left}" @click="stepAcive = index">
            <div class="processQunOne"></div>
            <div class="processQunTwo"></div>
            {{item.title}}
          </div>

        </div>
        <div v-for="(item,index) in stepList" :key="index">
          <div v-if="stepAcive === index" class="processItem">
            <div class="processItemTitle">{{item.textTitle}}</div>
            <div class="processText">{{item.content}}</div>
          </div>
        </div>
      </div>

      <div class="zzzsContent">
        <h4 class="descH4">资质证书</h4>
        <span class="line"></span>
        <div class="zzzsListCon">
          <div class="zzzsList" ref="zzzsData" >
            <img v-for="(item,index) in zzzsList" :key="index" :src="item.img" alt="" style="width:1760px;margin-top:50px;"  @mouseenter="handZzzs(index)" @mouseleave="handZzzs(null)">
          </div>
        </div>
      </div>

      <div class="ryzmCOntent">
        <h4 class="descH4">荣誉证明</h4>
        <span class="line"></span>
        <div class="ryzmListCOntent">
          <div class="maskLeft"></div>
          <div class="ryzmList" ref="thankData">
            <div v-for="(item,index) in thanksList" :key="index" class="ryzmItem" @mouseenter="handSchool(index)" @mouseleave="handSchool(null)">
              <img :src="item.src" alt="" style="width:285px">
            </div>
          </div>
          <div class="maskRIght"></div>
        </div>
      </div>

      <div class="joinUs">
        <h4 class="descH4">加入我们</h4>
        <span style="font-size:24px;color:#222;padding-bottom:25px">诚聘英才，易普行期待您的加入</span>
        <span class="line"></span>
        <div class="joinCompany">
          <el-carousel height="320px" class="joinLeft" arrow="never">
            <el-carousel-item v-for="(item,index) in companyList" :key="index" >
              <img :src="item.img" alt="" style="width:100%;height:100%">
            </el-carousel-item>
          </el-carousel>
          <div class="joinRight">
            <img src="@/assets/about/hi.png" alt="" style="width:60px;margin-bottom:24px">
            <span style="font-size:20px;color:#222;line-height:40px;text-shadow: 0px 8px 24px rgba(0,0,0,0.15);">这里拥有广阔发展平台和成长机遇，</span>
            <span style="font-size:20px;color:#222;line-height:40px;text-shadow: 0px 8px 24px rgba(0,0,0,0.15);">与志同道合的伙伴一起，成就公司，成就自己！</span>
            <button class="joinBtn" @click="dialogShow=true">加入我们</button>
          </div>
        </div>

      </div>

      <div class="joinUs">
        <h4 class="descH4">员工风采</h4>
        <span class="line"></span>
        <el-carousel height="320px" style="width:1200px;box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.15);border-radius: 10px;margin: 60px 0;"  arrow="never">
            <el-carousel-item v-for="(item,index) in ygList" :key="index" >
              <img :src="item.img" alt="" style="width:100%;object-fit: cover;">
            </el-carousel-item>
        </el-carousel>
      </div>

      <Foot></Foot>
    </div>

    <div class="fullPageVideo" v-if="play">
      <div class="videoWrap">
        <span class="el-icon-error closePlay" @click="play = false"></span>
        <video width="100%" autoplay loop  src="@/assets/about/vidio.mp4" controls></video>
      </div>
    </div>

    <el-dialog
      title="简历投递"
      :visible.sync="dialogShow"
      width="880px" top="15vh"
      :before-close="dialogClose">
      <div class="jianliCOntent">
        <div class="jianliTop">
          <img src="@/assets/jianli.png" alt="" style="width:100%">
          <div class="jianliTopText">诚聘英才，易普行期待您的加入</div>
        </div>
        <div style="margin:20px 0;">
          <div style="background:#fff2e1;padding:6px 16px;font-size: 16px;color:#666;">
            请您将简历通过邮件发送给我们，我们会认真评估您的简历，若有需要会与您取得联系。
          </div>
          <div style="font-size: 18px;color:#222;margin:20px 0;">
            <span style="display:inline-block;width:85px;text-align-last: justify;text-align: justify;">工作地点</span>：
            <span>北京、上海、武汉、广州</span>
          </div>
          <div style="font-size: 18px;color:#222;">
            <span style="display:inline-block;width:85px;text-align-last: justify;text-align: justify;">邮箱</span>：
            <span>pmh@epxing.com</span>
          </div>
        </div>
      </div>
    </el-dialog>
    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'About',
  data () {
    return {
      play: false,
      stepList: [
        { title: '2010', left: '0', content: '公司成立；开始从事高校人事信息化并始终保持专注；纯代码定制化，积累高校人力资源领域的业务、技术和项目经验。', textTitle: '2010年' },
        { title: '2013', left: '276px', content: '将高校人力资源业务做法抽象成标准功能，推出高校人力资源管理系统产品，迭代了V5/V6/V7三个版本；不断提升产品与高校业务的匹配度，聚焦于交付“功能”。', textTitle: '2013年' },
        { title: '2018', left: '534px', content: '聚焦于“最佳业务实践”，更注重高校个性化业务特点和需求；提高系统的可配置性、可扩展性等低代码能力，推出高校人力资源管理平台V8.0，走向项目化实施。', textTitle: '2018年' },
        { title: '2022', left: '778px', content: '正式推出高校低代码平台aPaaS/V9；聚焦于业务目标的实现，注重效率、专业能力、创新能力等用户多维价值，转型为解决方案模式。', textTitle: '2022年' },
        { title: '未来', left: '1024px', content: '将继续深耕高校业务，强化aPaaS能力，围绕“People+”，在应用、场景、技术、服务等创新方面，与高校用户、行业伙伴、领域专家等进行共创共享，希望能走向生态模式。', textTitle: '未来' }
      ],
      stepAcive: 0,
      thanksList: [
        { src: require('@/assets/about/thank/one.png') },
        { src: require('@/assets/about/thank/tow.png') },
        { src: require('@/assets/about/thank/three.png') },
        { src: require('@/assets/about/thank/four.png') },
        { src: require('@/assets/about/thank/five.png') },
        { src: require('@/assets/about/thank/six.png') },
        { src: require('@/assets/about/thank/seven.png') },
        { src: require('@/assets/about/thank/eight.png') },
        { src: require('@/assets/about/thank/nine.png') },
        { src: require('@/assets/about/thank/ten.png') },
        { src: require('@/assets/about/thank/eleven.png') },
        { src: require('@/assets/about/thank/twelve.png') },
        { src: require('@/assets/about/thank/one.png') },
        { src: require('@/assets/about/thank/tow.png') },
        { src: require('@/assets/about/thank/three.png') },
        { src: require('@/assets/about/thank/four.png') },
        { src: require('@/assets/about/thank/five.png') },
        { src: require('@/assets/about/thank/six.png') },
        { src: require('@/assets/about/thank/seven.png') },
        { src: require('@/assets/about/thank/eight.png') },
        { src: require('@/assets/about/thank/nine.png') },
        { src: require('@/assets/about/thank/ten.png') },
        { src: require('@/assets/about/thank/eleven.png') },
        { src: require('@/assets/about/thank/twelve.png') }

      ],
      timer: '',
      schoolShow: null,
      companyList: [
        { img: require('@/assets/about/bgs.png') },
        { img: require('@/assets/about/bgsTwo.png') },
        { img: require('@/assets/about/bgsThree.png') },
        { img: require('@/assets/about/bgsFour.png') }
      ],
      ygList: [
        { img: require('@/assets/about/ygOne.png') },
        { img: require('@/assets/about/ygTwo.png') },
        { img: require('@/assets/about/ygThree.png') },
        { img: require('@/assets/about/ygFour.png') }
      ],
      zzzsTime: null,
      zzzsList: [
        { img: require('@/assets/about/zzzs.png') },
        { img: require('@/assets/about/zzzs.png') },
        { img: require('@/assets/about/zzzs.png') },
        { img: require('@/assets/about/zzzs.png') }
      ],
      dialogShow: false

    }
  },
  watch: {
    form: {
      handler () {
        this.validFn()
      },
      deep: true
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moAbout')
      }
    },
    thankMove () {
      this.timer = setInterval(() => {
        if (!this.$refs.thankData) return
        if (
          this.$refs.thankData.offsetLeft <
          -this.$refs.thankData.offsetWidth / 2
        ) {
          this.$refs.thankData.style.left = 0
        } else {
          this.$refs.thankData.style.left =
          this.$refs.thankData.offsetLeft - 1 + 'px'
        }
      }, 15)
    },
    handSchool (index) {
      clearInterval(this.timer)
      this.schoolShow = index
      // const lis = Array.from(this.$refs.thankData.children)
      // lis.forEach((item) => {
      //   item.style.top = 0
      // })
      if (!index) {
        this.thankMove()
        return null
      }
      // lis[index].style.top = -18 + 'px'
    },
    zzzsMove () {
      this.zzzsTime = setInterval(() => {
        if (!this.$refs.zzzsData) return
        if (
          this.$refs.zzzsData.offsetLeft <
          -this.$refs.zzzsData.offsetWidth / 2
        ) {
          this.$refs.zzzsData.style.left = 0
        } else {
          this.$refs.zzzsData.style.left =
          this.$refs.zzzsData.offsetLeft - 1 + 'px'
        }
      }, 15)
    },
    handZzzs (index) {
      clearInterval(this.zzzsTime)
      if (!index && index !== 0) {
        this.zzzsMove()
        return null
      }
    },
    dialogClose () {
      this.dialogShow = false
    }

  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted () {
    this.thankMove()
    this.zzzsMove()
  }
}
</script>

<style scoped lang='scss'>
*{

  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.line{
  display: inline-block;
  width: 40px;
height: 6px;
background: #D70C0C;
border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.descH4{
font-size: 36px;
font-weight: 500;
color: #222222;
padding:60px 0 20px;
}
.aboutcontainer{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
.banner{
  width: 100%;
  height: 460px;
  background: url('~@/assets/about/banner.png') center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.banner button {
  width: 200px;
height: 60px;
background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
border-radius: 34px;
font-size: 16px;
color: #fff;
border: 0;
}
.banner button:hover {
box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
cursor: pointer;
}

.gsIntradition{
  width: 100%;
  height: 980px;
  background: url('~@/assets/about/gsBg.png') center no-repeat;
  background-size: cover;
  // background-image:url('~@/assets/about/intradutionBg.jpg');
  // background-position: bottom left;
  // background-size: 100%;
  // background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-container {
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 500px;
background-color: #333;
border-radius: 10px;
box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.intraditionContent{
  width: 1200px;
  height: 300px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  margin: 60px 0 85px;
  box-sizing: border-box;
}
.intraLeft{
  position:relative;
  top: 0;
  left: 0;
  width: 560px;
  height: 100%;
  overflow: hidden;
}
.palyFengm{
  width:560px;
  transform: scale(1);
  transition: transform 0.2s;
  cursor: pointer;
}
.palyFengm:hover{
  transform: scale(1.1);
}
.intraRight{
  width: 540px;
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 32px;
}
.intraPlayBtn{
  width: 68px;
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-40%) scale(1);
  cursor: pointer;
}
.intraPlayBtnHo
.fullPageVideo{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.2);
  z-index: 9999;
}
.closePlay{
  position: absolute;
  top: -50px;
  right: -100px;
  font-size: 50px;
  color: rgba(0, 0, 0,0.5);
  cursor: pointer;
}
.videoWrap{
  position: fixed;
  width: 900px;
  left: 50%;
  top: 55%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
}

.qiywh{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  // background: url('~@/assets/about/qiwenhuaBg.png') center no-repeat;
  // background-size: cover;
}
.qiywhImg{
  display: block;
  width: 100%;
  object-fit: cover;
}
.qiywhCon{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
.qiywhItem{
  flex: 1;
  height: 100%;
  background: linear-gradient(270deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.3) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
}

.qiywhItemImg{
  width: 60px;
}
.qiywhItemTitlt{
  font-size: 32px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 40px;
  margin-top: 12px;
}
.qiywhItemText{
  font-size:18px;
  line-height: 32px;
  color: #FFFFFF;
  text-align:center;
  width: 100%;
  height: 0;
  opacity: 0;
  padding: 0 60px;
  overflow: hidden;
  transition:opacity 0.5s,height 0.5s;
}
.qiywhItem:hover{
  background-color: rgba(0, 0, 0,0.1);
  backdrop-filter: blur(8px);

  .qiywhItemText{
    padding:20px 60px 0;
    opacity: 1;
    height: 140px;
  }
}

.processContent{
  width: 100%;
  height: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('~@/assets/about/processBg.png') center no-repeat;
  background-size: cover;
}
.processStep{
  position: relative;
  top: 0;
  left: 0;
}
.processStepItem{
  position: absolute;
  top: 0;
  background-color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.processQunOne{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  top:-5px;
  left: -5px;
  opacity: 0;
  border: 1px solid #D70C0C;
  transition: 0.5s;
}
.processQunTwo{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  top:-10px;
  left: -10px;
  opacity: 0;
  border: 1px solid #D70C0C;
  transition: 0.5s;
}
.processStepItem:hover{
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  color: #fff;

}
.processStepActive{
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  color: #fff;
  .processQunOne{
    opacity: 0.8;
  }
  .processQunTwo{
    opacity: 0.4;
  }
}
.processItem{
  width: 800px;
  margin-top: 60px;
  background: #FFFFFF;
  border-radius: 8px;
  opacity: 0.9;
  padding: 31px 40px;
}
.processItemTitle{
  font-size: 28px;
  font-weight: 500;
  color: #222222;
  line-height: 36px;
  margin-bottom: 10px;
}
.processText{
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}
.zzzsContent{
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.zzzsListCon{
  width:100%;
  height: 410px;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}
.zzzsList{
  width: 3520px;
  height: 410px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}
.ryzmCOntent{
  width: 100%;
  // height: 652px;
  background: #EFF1F3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}
.ryzmListCOntent{
  width: 100%;
  height: 400px;
  overflow: hidden;
  margin-top: 60px;
  position: relative;
  top: 0;
  left: 0;
}
.maskLeft{
  width: 200px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(270deg, rgba(239,241,243,0) 0%, #EFF1F3 100%);
  z-index: 100;
}
.maskRIght{
  width: 200px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(90deg, rgba(239,241,243,0) 0%, #EFF1F3 100%);
  z-index: 100;
}
.ryzmList{
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
}
.ryzmItem{
  width: 285px;
  cursor: pointer;
}
.joinUs{
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.joinCompany{
  width: 1200px;
  display: flex;
  box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.15);
  border-radius: 10px;
  margin: 60px 0;
}
.joinLeft{
  width: 600px;
  height: 320px;
  border-radius: 10px 0 0 10px;
}
.joinRight{
  width: 600px;
  height: 320px;
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-direction: column;
  padding-left:60px;
  padding-top: 45px;
}
.joinBtn{
  width: 200px;
  height: 48px;
  border: 0px;
  color: #fff;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 34px;
  margin-top: 30px;
}
.joinBtn:hover{
  box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.15), 0px 8px 32px -6px rgba(131,7,7,0.4);
}

.jianliCOntent{
  padding:0px 20px;
  box-sizing: border-box;
}
.jianliTop{
  position: relative;
  top: 0;
  left: 0;
}
.jianliTopText{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 20px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 28px;
}
.inputContent{
  padding: 20px 0 0;
  display: flex;
  flex-wrap: wrap;
}
.inputContentItem{

  flex-basis: 50%;
  box-sizing: border-box;
  margin-bottom: 30px;
}
.requireContent{
  position: absolute;
  bottom: -18px;
  left: 0;
  font-size: 13px;
  color: #f56c6c;
}

.inputContentItem:nth-child(odd){
  padding-right: 40px;

}
.inputContentItem:nth-child(even){
  padding-left: 40px;

}
.inputLable{
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 22px;
  margin-bottom: 10px;
}
.inputRequire{
  color: #FF3A3A;
  font-size: 16px;
}

.inputContentItem{
  &::v-deep {
    .el-input__inner,.el-input ,.el-select{
      width: 100%;
    }

  }
}
.el-select-dropdown__item{
  padding-left:20px
}
.upLoadBtn{
  width: 114px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #0874E7;

  font-size: 14px;
  font-weight: 400;
  color: #0874E7;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.jianliBottom{
  display: flex;
  justify-content: center;
  align-items: center;
}
.jianliBottomBtn{
  text-align: center;
  width: 100px;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;

  font-size: 16px;
  font-weight: 400;

  line-height: 40px;
}
</style>
